/* css for decidim_privacy */

.private-icon{
  top: 5px;
  position: relative;
}

#old_password_field{
  border: 2px red solid;
  padding: 1em;
  margin-bottom: 2em;
  border-color: var(--secondary);
  background-color: rgba(var(--secondary-rgb), .05);
  border-radius: 4px;
}
