$footer-bg: $turku-dark-grey;
$footer-color: $white;

.main-footer,
.mini-footer{
  color: $footer-color;

  a{
    color: $footer-color;

    &.hover,
    &:hover,
    &.focus,
    &:focus{
      color: $footer-color;
    }
  }
}

.main-footer,
.mini-footer{
  background-color: $footer-bg;
}

.main-footer__logo{
  max-width: 250px;
}

.main__footer__nav{
  text-align: left;
}

.footer-nav{
  li{
    display: block;
  }
}

.cc-badge{
  margin-right: .5rem;
}

.decidim-logo{
  display: block;
  width: 140px;
  margin-right: auto;
  margin-left: auto;
}
