a,
.link{
  font-weight: $anchor-font-weight;
}

.lead{
  font-weight: $lead-font-weight;
}

.section-heading{
  letter-spacing: normal;
  text-transform: none;
  font-size: 1.5em;

  &::before{
    display: none;
  }
}

.subheading1{
  text-align: left;
  margin: 0;
}

.page-title{
  margin-bottom: 1rem;
}

.page-title-wrapper{
  text-align: left;
}
