$turku-black: #000;
$turku-black-rgb: 0,0,0;
$turku-white: #fff;
$turku-white-rgb: 255,255,255;

$turku-grey: #c3c4b7;
$turku-grey-rgb: 195,196,183;
$turku-dark-grey: #404040;
$turku-dark-grey-rgb: 64,64,64;
$turku-light-grey: #f2f2f2;
$turku-light-grey-rgb: 242,242,242;

$turku-dark-blue: #006bb6;
$turku-dark-blue-rgb: 25,72,137;
$turku-yellow: #ffc233;
$turku-yellow-rgb: 255,194,51;
$turku-light-blue: #00aeef;
$turku-light-blue-rgb: 0,172,239;
$turku-red: #ed1a3b;
$turku-red-rgb: 237,26,59;
$turku-rubine-red: #e40c69; // Differs from brand because of accessibility
$turku-rubine-red-rgb: 228,12,105; // Differs from brand because of accessibility
$turku-orange: #f26522;
$turku-orange-rgb: 242,101,34;
$turku-green: #01835e; // Differs from brand because of accessibility
$turku-green-rgb: 1,131,94; // Differs from brand because of accessibility
$turku-light-green: #7dcdc8;
$turku-light-green-rgb: 125,205,200;

$turku-highlight: #00375e;
$turku-highlight-rgb: 0,55,94;
$turku-highlight-alternative: $turku-orange;
$turku-highlight-alternative-rgb: $turku-orange-rgb;

// Variables
$primary: $turku-rubine-red;
$primary-rgb: $turku-rubine-red-rgb;
$secondary: $turku-green;
$secondary-rgb: $turku-green-rgb;
$success: $turku-green;
$success-rgb: $turku-green-rgb;
$warning: $turku-orange;
$warning-rgb: $turku-orange-rgb;
$alert: $turku-red;
$alert-rgb: $turku-red-rgb;
$highlight: $turku-highlight;
$highlight-rgb: $turku-highlight-rgb;
$highlight-alternative: $turku-highlight-alternative;
$highlight-alternative-rgb: $turku-highlight-alternative-rgb;
