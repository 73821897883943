.timeline{
  .timeline__phase{
    background: $black;
    box-shadow: none;
    border: 0;
    margin-left: -20px;
    width: 50px;
    height: 50px;
  }

  .timeline__phase__number{
    margin-left: 4px;
  }

  .timeline__item{
    &::before,
    &::after{
      background: $black;
    }

    &::before{
      width: 10px;
    }

    &:last-of-type{
      &::before{
        width: 0;
      }
    }
  }

  .timeline__info{
    width: 84%;
    width: calc(100% - 65px);
    background: none;
    border: none;
  }

  .timeline__date{
    padding-right: 0;
    color: $body-font-color;
  }
}

.timeline__item{
  padding-bottom: 3em;
}

@include breakpoint(medium){
  .timeline-horizontal{
    display: flex;
    margin-bottom: 3rem;

    .timeline__item{
      flex: 1 1 auto;

      &::before,
      &::after{
        content: "";
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        width: 51%;
        height: 10px;
      }

      &::after{
        margin-right: -2px;
      }

      &::after{
        left: auto;
        right: -1%;
      }

      &:first-of-type{
        &::before{
          display: none;
        }
      }

      &:last-of-type{
        &::before{
          width: 51%;
        }

        &::after{
          display: none;
        }
      }
    }

    .timeline__phase{
      z-index: 1;
      top: auto;
      bottom: -20px;
      left: 50%;
      margin-left: 0;
    }

    .timeline__info{
      width: 87%;
      width: calc(100% - 50px);
      text-align: center;
    }
  }
}
