.button{
  box-shadow: $button-shadow;
  font-weight: $button-font-weight;

  &.active,
  &:active{
    top: $button-vertical-push-active;
    box-shadow: $button-shadow-active;
  }

  &.clear.secondary{
    color: $anchor-color;

    &:hover,
    &:focus{
      color: darken($anchor-color, 10%);
    }
  }
}

a.button{
  text-decoration: none;
}

.button--sc{
  text-transform: none;
}

.turku-vote-item{
  border-radius: 4px;
}
